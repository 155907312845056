<template>
  <section class="gen-section-padding-3 gen-single-video" >
        <div class="container">
            <div class="row no-gutters">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12" v-for="publish in contentData" :key="publish.id">
                            <div class="gen-video-holder">
                                
                                <video class="vvv"  controls="" controlsList="nodownload" preload="none" :poster="publish.carsoul_img">
                                <source :src="publish.source" type="Video/mp4" size="720">
                            </video>
                                
                                <!-- <iframe width="100%" height="550px" :src="publish.source"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe> -->
                            </div>
                        </div>
                        <div class="col-lg-12" v-for="publish in contentData" :key="publish.id">
                            <div class="single-video">
                                <div class="gen-single-video-info">
                                    <h4 class="gen-title">{{ publish.title_ar }}</h4>
                                    <div class="gen-single-meta-holder">
                                        <ul>
                                            <li>{{ publish.year }}</li>
                                            <li>
                                                <!-- <a href="#"><span></span></a> -->
                                            </li>
                                            <!-- <li>
                                                <i class="fas fa-eye">
                                                </i>
                                                <span>225 Views</span>
                                            </li> -->
                                        </ul>
                                    </div>
                                    <p>{{ publish.desc_ar }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="pm-inner">
                                <div class="gen-more-like">
                                    <h5 class="gen-more-title">فيديوهات اخرى</h5>
                                    <div class="row post-loadmore-wrapper">
                                        <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in getCatByID" :key="publish.id">
                                            <div class="gen-carousel-movies-style-3 movie-grid style-3">
                                                <div class="gen-movie-contain">
                                                    <div class="gen-movie-img">
                                                        <img :src="publish.carsoul_img"
                                                            alt="single-video-image">
                                                   
                                                        <div class="gen-movie-action">
                                                            <a @click="getFeed(publish)" class="gen-button">
                                                                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <polygon  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
      <path fill="none" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
    </svg> 
                                                                <!-- <i class="fa fa-play"></i> -->
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="gen-info-contain">
                                                        <div class="gen-movie-info">
                                                            <h3><a @click="getFeed(publish)">{{ publish.title_ar }}</a></h3>
                                                        </div>
                                                        <div class="gen-movie-meta-holder">
                                                            <ul>
                                                                <li>{{ publish.year }}</li>
                                                                <li>
                                                                    <a @click="getFeed(publish)"><span>{{ publish.name_ar }}</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </section>
</template>

<script>
import { useCookie } from 'vue-cookie-next'
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { HTTP } from '@/Api/http-common';
export default {
    name: 'AppContents',
    props: {
       content_id: String,
    },
    mounted() {
       if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
       }
    },
    setup(props) {
      const cookie = useCookie()
      const router = useRouter();
      const route = useRoute();
      const contentData = ref([]);
      const getCatByID = ref([]);

      try {
         HTTP.get('ContentById.php?CID='+ props.content_id).then((res) => {
           contentData.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
      try {
         HTTP.get('GetAllContent.php?'+'LIMIT=12&OFFSET=0').then((res) => {
           getCatByID.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
     
      const getFeed = (publish) => {
        // if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("minutes") > 0 ) {
          router.push({ name: "Contents", params: { content_id: publish.id } });
        // } else {
        //   router.push({ name: "Trailer" });
        // }
      };
      return {
        contentData,
        getCatByID,
        getFeed
      };
  },
}
</script>

<style>

</style>